<template>
  <div>
    <b-card
      class="mb-2"
    >
      <div class="position-relative">
        <div class="profile-img-container d-flex align-items-center">
          <div class="profile-img">
            <b-avatar
              :text="profileData.avatar_text"
              variant="primary"
              size="48"
            />
          </div>
          <div class="profile-title ml-3">
            <h2>
              {{ profileData.fullname }}
            </h2>
            <p>
              {{ $t(profileData.member_type) }}
            </p>
          </div>
        </div>
      </div>
    </b-card>
    <!-- <b-card v-if="profileData.member_type == 'Yatırımcı'">
      <b-row>
        <b-col md="6">
          <b-card :title="$t('Üyelik Bilgisi')">
            <b-row>
              <b-col md="6">
                <div
                  class="transaction-item"
                >
                  <b-media no-body>
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="42"
                        variant="success"
                      >
                        <feather-icon
                          size="18"
                          icon="CheckIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        Girişimci Paketi
                      </h6>
                      <small>{{ $t('Aktif Paketiniz') }}</small>
                    </b-media-body>
                  </b-media>
                </div>
              </b-col>
              <b-col md="6">
                <div
                  class="transaction-item"
                >
                  <b-media no-body>
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="42"
                        variant="info"
                      >
                        <feather-icon
                          size="18"
                          icon="ClockIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        8 Ay 19 Gün
                      </h6>
                      <small>{{ $t('Kalan Süre') }}</small>
                    </b-media-body>
                  </b-media>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card :title="$t('Veriler')">
            <b-row>
              <b-col md="6">
                <div
                  class="transaction-item"
                >
                  <b-media no-body>
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="42"
                        variant="success"
                      >
                        <feather-icon
                          size="18"
                          icon="CheckIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        1
                      </h6>
                      <small>{{ $t('Onaylanan Girişim') }}</small>
                    </b-media-body>
                  </b-media>
                </div>
              </b-col>
              <b-col md="6">
                <div
                  class="transaction-item"
                >
                  <b-media no-body>
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="42"
                        variant="info"
                      >
                        <feather-icon
                          size="18"
                          icon="ClockIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        13
                      </h6>
                      <small>{{ $t('Bekleyen Başvuru') }}</small>
                    </b-media-body>
                  </b-media>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card> -->
    <b-card>
      <b-row>
        <b-col md="12">
          <validation-observer ref="simpleRules">
            <b-card :title="$t('Hesap Bilgileri')">
              <validation-provider
                #default="{ errors }"
                name="Ad"
                rules="required"
              >
                <b-form-group
                  label="Ad"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="formData.name"
                    placeholder="Ad"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="Soyad"
                rules="required"
              >
                <b-form-group
                  label="Soyad"
                  label-for="surname"
                >
                  <b-form-input
                    id="surname"
                    v-model="formData.surname"
                    placeholder="Soyad"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="E-Posta"
                rules="required"
              >
                <b-form-group
                  label="E-Posta"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="formData.email"
                    placeholder="E-Posta"
                    disabled
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="Telefon"
                rules="required"
              >
                <b-form-group
                  label="Telefon"
                  label-for="phone"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      TR (+90)
                    </b-input-group-prepend>
                    <cleave
                      id="phone"
                      v-model="formData.phone"
                      class="form-control"
                      :raw="false"
                      :options="options.phone"
                      placeholder="1234 567 8900"
                    />
                  </b-input-group>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="Şehir"
                rules="required"
              >
                <b-form-group
                  label="Şehir"
                  label-for="city"
                >
                  <v-select
                    id="city"
                    v-model="formData.id_cities"
                    :options="cities"
                    :reduce="cities => cities.id"
                    label="title"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <b-form-checkbox
                v-model="updatePassword"
                checked="false"
                class="mb-2"
                name="check-button"
                switch
                inline
              >
                Parola Güncelle
              </b-form-checkbox>
              <b-form-group
                v-if="updatePassword"
                label="Yeni Şifre"
                label-for="password"
              >
                <b-input-group>
                  <b-form-input
                    id="basic-password"
                    v-model="formData.password"
                    :type="passwordFieldType"
                    placeholder="Yeni Şifre"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-card>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BAvatar, BMedia, BMediaAside, BMediaBody, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BButton, BFormCheckbox, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'Profile',
  components: {
    BRow,
    BCol,
    BCard,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
    vSelect,
    Cleave,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      submitStatus: false,
      formData: {
        name: null,
        surname: null,
        email: null,
        phone: null,
        password: null,
        id_cities: null,
      },
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'TR',
        },
      },
      updatePassword: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    profileData() {
      return this.$store.getters['profile/getProfile']
    },
    cities() {
      return this.$store.getters['cities/getCities']
    },
    saveStatus() {
      return this.$store.getters['profile/getProfileSaveStatus']
    },
  },
  watch: {
    profileData(val) {
      this.setFormData(val)
    },
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getProfileData()
    this.getCities()
    localize(this.$i18n.locale)
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getProfileData() {
      this.$store.dispatch('profile/profileView')
    },
    getCities() {
      this.$store.dispatch('cities/citiesList')
    },
    setFormData(data) {
      this.formData = data
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('profile/profileSave', this.formData)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
